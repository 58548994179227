import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getOurPartner } from 'actions/home.action'
import { RootState } from 'reducers/rootReducer'

export interface PartnersProps {}

export const usePartners = (props: PartnersProps) => {
  const dispatch = useAppDispatch()

  const ourPartnerData = useSelector(
    (state: RootState) => state.home.ourPartnerData
  )

  useEffect(() => {
    if (!ourPartnerData) dispatch(getOurPartner())
  }, [ourPartnerData])

  return {
    ...props,
    ourPartnerData,
  }
}
