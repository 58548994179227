import React from 'react'
import { useNewsListing, NewsListingProps } from './useNewsListing'
import NewsCard from 'components/NewsCard/NewsCard'

const NewsListing: React.FC<NewsListingProps> = (props: NewsListingProps) => {
  const {
    searchTerm,
    selectedCategory,
    paginatedNewsData,
    newsCount,
    newsCategories,
    isLoading,
    setSearchTerm,
    setSelectedCategory,
    handleLoadMore,
    handleSearch,
  } = useNewsListing(props)

  return (
    <section>
      <div className='container pt-5'>
        <div className='row mb-4'>
          <div className='col-12 col-md-4 mb-2 mb-lg-0'>
            <input
              type='search'
              className='form-control'
              placeholder='العنوان...'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className='col-12 col-md-4 mb-2 mb-lg-0'>
            <select
              className='form-select'
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value='0'>الفئة</option>
              {newsCategories &&
                newsCategories.length &&
                newsCategories.map((category, index) => (
                  <option
                    key={`category-${category._id}-${index}`}
                    value={category._id}
                  >
                    {category.name}
                  </option>
                ))}
            </select>
          </div>
          <div className='col-12 col-md-4'>
            <button
              type='button'
              className='btn btn-secondary fw-bold w-100'
              onClick={() => handleSearch()}
            >
              <i className='bi bi-search me-2'></i>بحث
            </button>
          </div>
        </div>
        <div className='row'>
          {paginatedNewsData && paginatedNewsData.length ? (
            paginatedNewsData.map((news, index) => {
              const { content } = news
              let shortDescription = ''

              if (
                content.length > 0 &&
                content[0].children &&
                content[0].children.length > 0
              ) {
                const firstText = content[0].children[0].text
                shortDescription = `${firstText.substring(0, 100)}...`
              } else shortDescription = 'لا يوجد تفاصيل...'

              return (
                <div
                  key={`news-${news._id}-${index}`}
                  className='col-12 col-md-6 col-lg-4 mb-3'
                >
                  <NewsCard
                    image={news.image}
                    title={news.title}
                    description={shortDescription}
                    link={`/NewsDetails/${news.slug}`}
                    date={news._updatedAt.split('T')[0]}
                  />
                </div>
              )
            })
          ) : (
            <div className='col-12 text-center fs-4 fw-bold'>
              لا يوجد بيانات
            </div>
          )}
        </div>
        <div className='row mt-4 mb-5'>
          <div className='col-12 text-center'>
            <button
              type='button'
              className='btn btn-secondary px-5 py-2'
              style={{
                display:
                  paginatedNewsData &&
                  paginatedNewsData.length &&
                  newsCount > paginatedNewsData.length
                    ? 'initial'
                    : 'none',
              }}
              disabled={isLoading}
              onClick={() => handleLoadMore()}
            >
              {isLoading ? 'يرجى الإنتطار...' : 'المزيد'}
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NewsListing
