import React, { Fragment } from 'react'
import { useAbout, AboutProps } from './useAbout'
import blockContentToHtml from '@sanity/block-content-to-html'
import styles from './About.module.scss'
import TitleSection from 'components/TitleSection/TitleSection'
import FeatureCard from 'components/FeatureCard/FeatureCard'

const About: React.FC<AboutProps> = (props: AboutProps) => {
  const { aboutUsData } = useAbout(props)

  return (
    <section id='about' className={styles.about}>
      <TitleSection title='من نحن' />
      {aboutUsData && aboutUsData.length ? (
        <div key={aboutUsData[0]._id} className='container'>
          <div className='row mb-5 position-relative'>
            <div className={`col-lg-7 ${styles['img-container']}`}>
              <img
                src={aboutUsData[0].image}
                alt='about us'
                loading='lazy'
                className={styles.img}
              />
            </div>
            <div className='col-lg-7'>
              <h2 className={styles.title}>ما هو مشروع وحدة وطن؟</h2>
              <div className={styles.story}>
                <h4 className={styles['story-title']}>منذ 2024</h4>
                <h3>{aboutUsData[0].ourHistory.title}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: Array.isArray(aboutUsData[0].ourHistory.description)
                      ? blockContentToHtml({
                          blocks: aboutUsData[0].ourHistory.description,
                        })
                      : `<p>${aboutUsData[0].ourHistory.description}</p>`,
                  }}
                ></div>
                <h3>{aboutUsData[0].ourVision.title}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: Array.isArray(aboutUsData[0].ourVision.description)
                      ? blockContentToHtml({
                          blocks: aboutUsData[0].ourVision.description,
                        })
                      : `<p>${aboutUsData[0].ourVision.description}</p>`,
                  }}
                ></div>
                <a href='#contact' className='btn btn-secondary px-5 py-2 mt-2'>
                  تواصل معنا
                </a>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6 mb-3'>
              <FeatureCard
                icon='bi bi-bullseye'
                title={aboutUsData[0].ourMission.title}
                subTitle={aboutUsData[0].ourMission.subtitle}
                description={{
                  __html: Array.isArray(aboutUsData[0].ourMission.description)
                    ? blockContentToHtml({
                        blocks: aboutUsData[0].ourMission.description,
                      })
                    : `<p>${aboutUsData[0].ourMission.description}</p>`,
                }}
              />
            </div>
            <div className='col-lg-6 mb-3'>
              <FeatureCard
                icon='bi bi-gem'
                title={aboutUsData[0].ourValues.title}
                subTitle={aboutUsData[0].ourValues.subtitle}
                description={{
                  __html: Array.isArray(aboutUsData[0].ourValues.description)
                    ? blockContentToHtml({
                        blocks: aboutUsData[0].ourValues.description,
                      })
                    : `<p>${aboutUsData[0].ourValues.description}</p>`,
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <Fragment />
      )}
    </section>
  )
}

export default About
