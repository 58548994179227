import React from 'react'
import { useAuthorCard, AuthorCardProps } from './useAuthorCard'
import styles from './AuthorCard.module.scss'

const AuthorCard: React.FC<AuthorCardProps> = (props: AuthorCardProps) => {
  const { name, description, url, photo } = useAuthorCard(props)

  return (
    <div className={styles['author-card']}>
      <h4 className={styles.title}>الكاتب</h4>
      <div className='d-flex flex-column align-items-center'>
        <img
          src={photo}
          width={160}
          className='rounded-circle flex-shrink-0'
          alt='author image'
        />
        {url ? (
          <a
            href={url}
            target='_blank'
            rel='noreferrer'
            className={styles.link}
          >
            <h4 dir='ltr'>
              {name} <i className='bi bi-box-arrow-up-right'></i>
            </h4>
          </a>
        ) : (
          <h4 dir='ltr'>{name}</h4>
        )}
        <p className='mb-0'>{description}</p>
      </div>
    </div>
  )
}

export default AuthorCard
