import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useRouter, RouterProps } from './useRouter'
import Home from 'screens/Home/Home'
import News from 'screens/News/News'
import Articles from 'screens/Articles/Articles'
import Observatory from 'screens/Obeservatory/Observatory'
import NewsDetails from 'screens/NewsDetails/NewsDetails'
import ArticleDetails from 'screens/ArticleDetails/ArticleDetails'

const Router: React.FC<RouterProps> = (props: RouterProps) => {
  const {} = useRouter(props)

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/News' element={<News />} />
        <Route path='/Articles' element={<Articles />} />
        <Route path='/Observatory' element={<Observatory />} />
        <Route path='/NewsDetails/:slug' element={<NewsDetails />} />
        <Route path='/ArticleDetails/:slug' element={<ArticleDetails />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
