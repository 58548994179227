import React from 'react'
import { useHero, HeroProps } from './useHero'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Parallax } from 'swiper/modules'
import styles from './Hero.module.scss'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const Hero: React.FC<HeroProps> = (props: HeroProps) => {
  const { sliderData } = useHero(props)

  return (
    <section id='hero' className={styles.hero}>
      <Swiper
        dir='rtl'
        navigation
        loop
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        style={
          {
            '--swiper-navigation-color': '#fff',
            '--swiper-pagination-color': '#fff',
          } as React.CSSProperties
        }
        parallax={true}
        modules={[Navigation, Pagination, Parallax]}
        className={styles.swiper}
        data-swiper-parallax='-23%'
      >
        {sliderData &&
          sliderData.length &&
          sliderData.map((slider, index) => (
            <SwiperSlide
              className={styles.slide}
              key={`slider-${slider._id}-${index}`}
            >
              <img
                src={slider.image_BG}
                alt='slider image'
                className={styles.background}
                loading={index ? 'lazy' : 'eager'}
              />
              <div className={styles.content}>
                <h1 className='mb-4' data-swiper-parallax='-500'>
                  {slider.title}
                </h1>
                <div className='mb-4' data-swiper-parallax='-300'>
                  <p className={styles.description}>{slider.description}</p>
                </div>
                <div data-swiper-parallax='-100'>
                  <a
                    href={slider.button_link}
                    className='btn btn-secondary px-5 py-2'
                  >
                    {slider.button_title}
                  </a>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </section>
  )
}

export default Hero
