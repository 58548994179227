import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  sendContactMessageRequest,
  getSlider as getSliderReducer,
  getAboutUs as getAboutUsReducer,
  getOurTeam as getOurTeamReducer,
  getOurPartner as getOurPartnerReducer,
  getSocialMedia as getSocialMediaReducer,
  sendContactMessage as sendContactMessageReducer,
  sendContactMessageFailed,
} from 'reducers/home.reducer'
import { homeService } from 'services/home.service'
import { IContactMutation } from 'models/Home/IContact'

export const getSlider = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await homeService.getSlider()
    if (response) {
      const sliderData = response

      dispatch(getSliderReducer({ sliderData }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const getAboutUs = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await homeService.getAboutUs()
    if (response) {
      const aboutUsData = response

      dispatch(getAboutUsReducer({ aboutUsData }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const getOurTeam = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await homeService.getOurTeam()
    if (response) {
      const ourTeamData = response

      dispatch(getOurTeamReducer({ ourTeamData }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const getOurPartner = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await homeService.getOurPartner()
    if (response) {
      const ourPartnerData = response

      dispatch(getOurPartnerReducer({ ourPartnerData }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const getSocialMedia = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await homeService.getSocialMedia()
    if (response) {
      const socialMedia = response

      dispatch(getSocialMediaReducer({ socialMedia }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const sendContactMessage =
  (contactInfo: IContactMutation) => async (dispatch: Dispatch) => {
    try {
      dispatch(sendContactMessageRequest())

      const response = await homeService.sendContactMessage(contactInfo)
      if (response) {
        const contactMessageResponse = response
        dispatch(sendContactMessageReducer({ contactMessageResponse }))
      }
    } catch (error) {
      dispatch(apiFailure())
      dispatch(sendContactMessageFailed())
    }
  }
