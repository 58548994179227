import React from 'react'
import { useContactCard, ContactCardProps } from './useContactCard'
import styles from './ContactCard.module.scss'

const ContactCard: React.FC<ContactCardProps> = (props: ContactCardProps) => {
  const { icon, title, description, isPhone, isEmail } = useContactCard(props)

  return (
    <div className={styles['contact-card']}>
      <i className={`${styles.icon} ${icon}`}></i>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>
        {isEmail ? (
          <a href={`mailto:${description}`} className={styles.link}>
            {description}
          </a>
        ) : isPhone ? (
          <a href={`tel:${description}`} className={styles.link} dir='ltr'>
            {description}
          </a>
        ) : (
          description
        )}
      </p>
    </div>
  )
}

export default ContactCard
