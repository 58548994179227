import makeRequest from 'utils/makeRequest'
import { ALL_ENDPOINTS } from 'config/apiEndpoints'
import ISliderResponse from 'models/Home/ISlider'
import IAboutUsResponse from 'models/Home/IAboutUs'
import IOurTeamResponse from 'models/Home/IOurTeam'
import IOurPartnerResponse from 'models/Home/IOurPartner'
import ISocialMediaResponse from 'models/Home/ISocialMedia'
import { IContactMutation } from 'models/Home/IContact'

export const homeService = {
  getSlider: async (): Promise<ISliderResponse> => {
    try {
      const response: ISliderResponse = await makeRequest(
        'GET',
        ALL_ENDPOINTS.Home.GetSlider
      )

      return response
    } catch (error) {
      throw new Error('Get slider data failed')
    }
  },
  getAboutUs: async (): Promise<IAboutUsResponse> => {
    try {
      const response: IAboutUsResponse = await makeRequest(
        'GET',
        ALL_ENDPOINTS.Home.GetAboutUs
      )

      return response
    } catch (error) {
      throw new Error('Get about us data failed')
    }
  },
  getOurTeam: async (): Promise<IOurTeamResponse> => {
    try {
      const response: IOurTeamResponse = await makeRequest(
        'GET',
        ALL_ENDPOINTS.Home.GetOurTeam
      )

      return response
    } catch (error) {
      throw new Error('Get our team data failed')
    }
  },
  getOurPartner: async (): Promise<IOurPartnerResponse> => {
    try {
      const response: IOurPartnerResponse = await makeRequest(
        'GET',
        ALL_ENDPOINTS.Home.GetOurPartner
      )

      return response
    } catch (error) {
      throw new Error('Get our partners data failed')
    }
  },
  getSocialMedia: async (): Promise<ISocialMediaResponse> => {
    try {
      const response: ISocialMediaResponse = await makeRequest(
        'GET',
        ALL_ENDPOINTS.Home.GetSocialMedia
      )

      return response
    } catch (error) {
      throw new Error('Get social media data failed')
    }
  },
  sendContactMessage: async (contactInfo: IContactMutation): Promise<any> => {
    try {
      const response: any = await makeRequest(
        'POST',
        ALL_ENDPOINTS.ContactForm.Post,
        contactInfo
      )

      return response
    } catch (error) {
      throw new Error('Send contact message failed')
    }
  },
}
