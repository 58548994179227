import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getLatestNews } from 'actions/news.action'
import { RootState } from 'reducers/rootReducer'

export interface NewsProps {}

export const useNews = (props: NewsProps) => {
  const dispatch = useAppDispatch()

  const latestNewsData = useSelector(
    (state: RootState) => state.news.latestNewsData
  )

  useEffect(() => {
    if (!latestNewsData) dispatch(getLatestNews())
  }, [latestNewsData])

  return {
    ...props,
    latestNewsData,
  }
}
