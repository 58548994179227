import React from 'react'
import { useFeatureCard, FeatureCardProps } from './useFeatureCard'
import styles from './FeatureCard.module.scss'

const FeatureCard: React.FC<FeatureCardProps> = (props: FeatureCardProps) => {
  const { icon, title, subTitle, description } = useFeatureCard(props)

  return (
    <div className={styles['feature-card']}>
      <i className={`${icon} ${styles.icon}`}></i>
      <div>
        <h4 className={styles.title}>{title}</h4>
        <b>{subTitle}</b>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={description}
        ></div>
      </div>
    </div>
  )
}

export default FeatureCard
