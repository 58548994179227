import React from 'react'
import { useTags, TagsProps } from './useTags'
import Tag from 'components/Tag/Tag'

const Tags: React.FC<TagsProps> = (props: TagsProps) => {
  const { tags } = useTags(props)

  return (
    <div className='d-flex flex-wrap mt-4'>
      {tags &&
        tags.length &&
        tags.map((tag, index) => (
          <Tag key={`tag-${tag._id}-${index}`} name={tag.tag} />
        ))}
    </div>
  )
}

export default Tags
