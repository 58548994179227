import React from 'react'
import { useLatestArticles, LatestArticlesProps } from './useLatestArticles'
import styles from './LatestArticles.module.scss'
import LatestPost from 'components/LatestPost/LatestPost'

const LatestArticles: React.FC<LatestArticlesProps> = (
  props: LatestArticlesProps
) => {
  const { blogs } = useLatestArticles(props)

  return (
    <div className={`${styles['latest-articles']} mt-4 mt-lg-0`}>
      <h4 className={styles.title}>آخر المقالات</h4>
      {blogs &&
        blogs.length &&
        blogs.map((blogs, index) => (
          <LatestPost
            key={`blogs-${blogs._id}-${index}`}
            image={blogs.image}
            title={blogs.title}
            link={`/ArticleDetails/${blogs.slug}`}
            date={blogs._updatedAt.split('T')[0]}
          />
        ))}
    </div>
  )
}

export default LatestArticles
