export interface TeamCardProps {
  image: string
  name: string
  position: string
  description: string
  socialLinks: SocialLinks
}

interface SocialLinks {
  facebook?: string
  instagram?: string
  x?: string
  linkedIn?: string
}

export const useTeamCard = (props: TeamCardProps) => {
  return {
    ...props,
  }
}
