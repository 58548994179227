import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { sendContactMessage } from 'actions/home.action'
import { RootState } from 'reducers/rootReducer'
import IContact, { IContactMutation } from 'models/Home/IContact'

export interface ContactProps {}

export const useContact = (props: ContactProps) => {
  const dispatch = useAppDispatch()

  const socialMedia = useSelector((state: RootState) => state.home.socialMedia)
  const isLoading = useSelector(
    (state: RootState) => state.home.isLoadingContactMessage
  )
  const isSendContactMessageSuccess = useSelector(
    (state: RootState) => state.home.isSendContactMessageSuccess
  )
  const isSendContactMessageFailed = useSelector(
    (state: RootState) => state.home.isSendContactMessageFailed
  )

  const [contactFormData, setContactFormData] = useState<IContact>({
    name: '',
    email: '',
    subject: '',
    message: '',
  })

  const handleControllerChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setContactFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      if (
        contactFormData.name !== '' ||
        contactFormData.email !== '' ||
        contactFormData.subject !== '' ||
        contactFormData.message !== ''
      ) {
        const contactMutation: IContactMutation = {
          mutations: [
            {
              create: {
                _type: 'contactForm',
                ...contactFormData,
              },
            },
          ],
        }

        await dispatch(sendContactMessage(contactMutation))
      }
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }

  useEffect(() => {
    if (isSendContactMessageSuccess)
      setContactFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
      })
  }, [isSendContactMessageSuccess])

  return {
    ...props,
    socialMedia,
    contactFormData,
    isLoading,
    isSendContactMessageSuccess,
    isSendContactMessageFailed,
    handleControllerChange,
    handleFormSubmit,
  }
}
