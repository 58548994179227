import React from 'react'
import { useNewsDetails, NewsDetailsProps } from './useNewsDetails'
import blockContentToHtml from '@sanity/block-content-to-html'
import styles from './NewsDetails.module.scss'
import InnerLayout from 'components/InnerLayout/InnerLayout'
import InnerTitleSection from 'components/InnerTitleSection/InnerTitleSection'
import LatestNews from './LatestNews/LatestNews'
import Tags from './Tags/Tags'

const NewsDetails: React.FC<NewsDetailsProps> = (props: NewsDetailsProps) => {
  const { newsDetails, latestNews, tags } = useNewsDetails(props)

  return (
    <InnerLayout>
      <main>
        <InnerTitleSection
          title='تفاصيل الخبر'
          breadcrumbs={[
            {
              title: 'الرئيسية',
              link: '/',
            },
            {
              title: 'الأخبار',
              link: '/News',
            },
            {
              title: 'تفاصيل الخبر',
            },
          ]}
        />
        <article className={styles['news-details']}>
          <div className='container'>
            {newsDetails && newsDetails.length && (
              <div className='row'>
                <div className='col-lg-8 col-xl-9'>
                  <img src={newsDetails[0].image} className={styles.image} />
                  <div className={styles.content}>
                    <h1 className={styles.title}>{newsDetails[0].title}</h1>
                    <div className={styles.date}>
                      <i className='bi bi-clock me-2'></i>
                      <time dir='ltr'>
                        {`${newsDetails[0]._updatedAt.split('T')[0]} ${newsDetails[0]._updatedAt.split('T')[1].slice(0, -1)}`}
                      </time>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: Array.isArray(newsDetails[0].content)
                          ? blockContentToHtml({
                              blocks: newsDetails[0].content,
                            })
                          : `<p>${newsDetails[0].content}</p>`,
                      }}
                    ></div>
                  </div>
                </div>
                <div className='col-lg-4 col-xl-3'>
                  <LatestNews news={latestNews} />
                  <Tags tags={tags} />
                </div>
              </div>
            )}
          </div>
        </article>
      </main>
    </InnerLayout>
  )
}

export default NewsDetails
