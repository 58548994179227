import React from 'react'
import { useReportCard, ReportCardProps } from './useReportCard'
import styles from './ReportCard.module.scss'
import Tag from 'components/Tag/Tag'

const ReportCard: React.FC<ReportCardProps> = (props: ReportCardProps) => {
  const { title, description, author, authorPicture, report, tags } =
    useReportCard(props)

  return (
    <div className={styles['report-card']}>
      <i
        className={`bi bi-file-earmark-pdf ${styles['report-card-background-icon']}`}
      ></i>
      <div className={styles['report-card-content']}>
        <h3 className={styles['report-card-title']}>{title}</h3>
        <h6 className={styles['report-card-author']}>
          <img src={authorPicture} width={25} height={25} alt='author image' />
          {author}
        </h6>
        <p className={`${styles.description} mt-4`}>{description}</p>
        <hr className={styles['report-card-splitter']} />
        <div className='d-flex flex-wrap'>
          {tags &&
            tags.length &&
            tags.map((tag, index) => (
              <Tag key={`tag-${tag._id}-${index}`} name={tag.tag} isSmall />
            ))}
        </div>
        <a
          href={report}
          target='_blank'
          rel='noreferrer'
          download
          className={styles['report-card-link']}
        >
          <i className='bi bi-download me-2'></i>
          <span className={styles['report-card-link-text']}>تحميل الملف</span>
        </a>
      </div>
    </div>
  )
}

export default ReportCard
