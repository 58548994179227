import { createSlice } from '@reduxjs/toolkit'
import { INews } from 'models/News/INews'
import { INewsCategory } from 'models/News/INewsCategory'
import { INewsDetails } from 'models/News/INewsDetails'

export interface NewsState {
  isLoading: boolean
  isLoadingPaginatedNews: boolean
  isGetLatestNewsSuccess: boolean
  isGetNewsCountSuccess: boolean
  isGetNewsCategoriesSuccess: boolean
  isGetNewsDetailsSuccess: boolean
  latestNewsData: INews[] | null
  paginatedNewsData: INews[] | null
  newsCategories: INewsCategory[] | null
  newsDetails: INewsDetails[] | null
  newsCount: number
}

const initialState: NewsState = {
  isLoading: false,
  isLoadingPaginatedNews: false,
  isGetLatestNewsSuccess: false,
  isGetNewsCountSuccess: false,
  isGetNewsCategoriesSuccess: false,
  isGetNewsDetailsSuccess: false,
  latestNewsData: null,
  paginatedNewsData: null,
  newsCategories: null,
  newsDetails: null,
  newsCount: 0,
}

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
      state.isLoadingPaginatedNews = false
    },
    paginatedNewsRequest: (state) => {
      state.isLoadingPaginatedNews = true
    },
    clearNewsDetails: (state) => {
      state.isGetNewsDetailsSuccess = false
      state.newsDetails = null
    },
    getLatestNews(state, action) {
      state.isLoading = false
      state.isGetLatestNewsSuccess = true
      state.latestNewsData = action.payload.latestNewsData.result
    },
    getNewsCount(state, action) {
      state.isLoading = false
      state.isGetNewsCountSuccess = true
      state.newsCount = action.payload.newsCount.result.length
    },
    getNewsCategories(state, action) {
      state.isLoading = false
      state.isGetNewsCategoriesSuccess = true
      state.newsCategories = action.payload.newsCategories.result.shift()
    },
    getNewsDetails(state, action) {
      state.isLoading = false
      state.isGetNewsDetailsSuccess = true
      state.newsDetails = action.payload.newsDetails.result
    },
    getPaginatedNewsSearch(state, action) {
      state.isLoadingPaginatedNews = false
      state.paginatedNewsData = action.payload.paginatedNews.result
    },
    getPaginatedNewsLoadMore(state, action) {
      state.isLoadingPaginatedNews = false

      const existingIds = new Set(
        state.paginatedNewsData?.map((news) => news._id) || []
      )
      const newUniqueNews = action.payload.paginatedNews.result.filter(
        (news: any) => !existingIds.has(news._id)
      )

      state.paginatedNewsData = [
        ...(state.paginatedNewsData || []),
        ...newUniqueNews,
      ]
    },
  },
})

export const {
  apiRequest,
  apiFailure,
  paginatedNewsRequest,
  clearNewsDetails,
  getLatestNews,
  getNewsCount,
  getPaginatedNewsSearch,
  getPaginatedNewsLoadMore,
  getNewsCategories,
  getNewsDetails,
} = newsSlice.actions

export default newsSlice.reducer
