export interface NewsCardProps {
  image: string
  title: string
  description: string
  link: string
  date: string
}

export const useNewsCard = (props: NewsCardProps) => {
  const { date } = props

  const splittedDate = date.split('-')
  let finalDate = date

  if (splittedDate.length > 1)
    finalDate = `${splittedDate[2]}.${splittedDate[1]}.${splittedDate[0]}`

  return {
    ...props,
    finalDate,
  }
}
