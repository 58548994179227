import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  paginatedBlogsRequest,
  getLatestBlogs as getLatestBlogsReducer,
  getBlogsCount as getBlogsCountReducer,
  getPaginatedBlogsSearch as getPaginatedBlogsReducerSearch,
  getPaginatedBlogsLoadMore as getPaginatedBlogsLoadMoreReducer,
  getBlogCategories as getBlogCategoriesReducer,
  getBlogDetails as getBlogDetailsReducer,
  clearBlogDetails as clearBlogDetailsReducer,
} from 'reducers/blogs.reducer'
import { blogsService } from 'services/blogs.service'

export const getLatestBlogs = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await blogsService.getLatestBlogs()
    if (response) {
      const latestBlogsData = response

      dispatch(getLatestBlogsReducer({ latestBlogsData }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const getBlogsCount = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await blogsService.getBlogsCount()
    if (response) {
      const blogsCount = response

      dispatch(getBlogsCountReducer({ blogsCount }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const getPaginatedBlogs =
  (from: number, to: number, searchTerm: string, categoryId: string) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(paginatedBlogsRequest())

      const response = await blogsService.getPaginatedBlogs(
        from,
        to,
        searchTerm,
        categoryId
      )
      if (response) {
        const paginatedBlogs = response

        if (from === 0)
          dispatch(getPaginatedBlogsReducerSearch({ paginatedBlogs }))
        else dispatch(getPaginatedBlogsLoadMoreReducer({ paginatedBlogs }))
      }
    } catch (error) {
      dispatch(apiFailure())
    }
  }

export const getBlogCategories = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await blogsService.getBlogCategories()
    if (response) {
      const blogCategories = response

      dispatch(getBlogCategoriesReducer({ blogCategories }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const getBlogDetails = (slug: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await blogsService.getBlogDetails(slug)
    if (response) {
      const blogDetails = response

      dispatch(getBlogDetailsReducer({ blogDetails }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const clearBlogDetails = () => (dispatch: Dispatch) => {
  try {
    dispatch(clearBlogDetailsReducer())
  } catch (error) {
    console.error('Could not clear blog details state')
  }
}
