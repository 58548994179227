import React, { useEffect } from 'react'
import InnerLayout from 'components/InnerLayout/InnerLayout'
import NewsListing from './NewsListing/NewsListing'
import InnerTitleSection from 'components/InnerTitleSection/InnerTitleSection'

const News: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <InnerLayout>
      <main>
        <InnerTitleSection
          title='الأخبار'
          breadcrumbs={[
            {
              title: 'الرئيسية',
              link: '/',
            },
            {
              title: 'الأخبار',
            },
          ]}
        />
        <NewsListing />
      </main>
    </InnerLayout>
  )
}

export default News
