import React, { useEffect } from 'react'
import InnerLayout from 'components/InnerLayout/InnerLayout'
import ArticlesListing from './ArticlesListing/ArticlesListing'
import InnerTitleSection from 'components/InnerTitleSection/InnerTitleSection'

const Articles: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <InnerLayout>
      <main>
        <InnerTitleSection
          title='المقالات'
          breadcrumbs={[
            {
              title: 'الرئيسية',
              link: '/',
            },
            {
              title: 'المقالات',
            },
          ]}
        />
        <ArticlesListing />
      </main>
    </InnerLayout>
  )
}

export default Articles
