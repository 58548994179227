import React from 'react'
import Layout from 'components/Layout/Layout'
import Hero from './hero/Hero'
import About from './about/About'
import Team from './team/Team'
import News from './news/News'
import Articles from './articles/Articles'
import Contact from './contact/Contact'
import Partners from './partners/Partners'

const Home: React.FC = () => {
  return (
    <Layout>
      <main>
        <Hero />
        <About />
        <Team />
        <News />
        <Articles />
        <Contact />
        <Partners />
      </main>
    </Layout>
  )
}

export default Home
