export interface IndicatorCardProps {
  name: string
  scale: string
  description: string
  status: string
}

export const useIndicatorCard = (props: IndicatorCardProps) => {
  enum Status {
    Up = 'up',
    Down = 'down',
    Stable = 'stable',
  }

  return {
    ...props,
    Status,
  }
}
