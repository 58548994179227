import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  paginatedNewsRequest,
  getLatestNews as getLatestNewsReducer,
  getNewsCount as getNewsCountReducer,
  getPaginatedNewsSearch as getPaginatedNewsSearchReducer,
  getPaginatedNewsLoadMore as getPaginatedNewsLoadMoreReducer,
  getNewsCategories as getNewsCategoriesReducer,
  getNewsDetails as getNewsDetailsReducer,
  clearNewsDetails as clearNewsDetailsReducer,
} from 'reducers/news.reducer'
import { newsService } from 'services/news.service'

export const getLatestNews = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await newsService.getLatestNews()
    if (response) {
      const latestNewsData = response

      dispatch(getLatestNewsReducer({ latestNewsData }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const getNewsCount = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await newsService.getNewsCount()
    if (response) {
      const newsCount = response

      dispatch(getNewsCountReducer({ newsCount }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const getPaginatedNews =
  (from: number, to: number, searchTerm: string, categoryId: string) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(paginatedNewsRequest())

      const response = await newsService.getPaginatedNews(
        from,
        to,
        searchTerm,
        categoryId
      )
      if (response) {
        const paginatedNews = response

        if (from === 0)
          dispatch(getPaginatedNewsSearchReducer({ paginatedNews }))
        else dispatch(getPaginatedNewsLoadMoreReducer({ paginatedNews }))
      }
    } catch (error) {
      dispatch(apiFailure())
    }
  }

export const getNewsCategories = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await newsService.getNewsCategories()
    if (response) {
      const newsCategories = response

      dispatch(getNewsCategoriesReducer({ newsCategories }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const getNewsDetails = (slug: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await newsService.getNewsDetails(slug)
    if (response) {
      const newsDetails = response

      dispatch(getNewsDetailsReducer({ newsDetails }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const clearNewsDetails = () => (dispatch: Dispatch) => {
  try {
    dispatch(clearNewsDetailsReducer())
  } catch (error) {
    console.error('Could not clear news details state')
  }
}
