import React from 'react'
import { useIndicators, IndicatorsProps } from './useIndicators'
import TitleSection from 'components/TitleSection/TitleSection'
import IndicatorCard from 'components/IndicatorCard/IndicatorCard'
import styles from './Indicators.module.scss'

const Indicators: React.FC<IndicatorsProps> = (props: IndicatorsProps) => {
  const { indicatorsData } = useIndicators(props)

  return (
    <section className='py-5'>
      <div className='container'>
        <TitleSection title='المؤشرات' />
        <div className={styles['grid-container']}>
          {indicatorsData &&
            indicatorsData.length &&
            indicatorsData.map((indicator, index) => (
              <div
                key={`indicator-${indicator._id}-${index}`}
                className={styles['grid-item']}
              >
                <IndicatorCard
                  name={indicator.indicatorName}
                  scale={indicator.indicatorScale}
                  description={indicator.description}
                  status={indicator.upOrDown}
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Indicators
