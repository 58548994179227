import React from 'react'
import { Link } from 'react-router-dom'
import { useHeader, HeaderProps } from './useHeader'
import styles from './Header.module.scss'

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { scrolled, activeSection } = useHeader(props)

  return (
    <header>
      <nav
        className={`${styles.navbar} ${scrolled ? styles.scrolled : ''} navbar fixed-top navbar-expand-lg`}
      >
        <div className='container'>
          <a className='navbar-brand' href='#'>
            <img
              src='/img/logos/logo-colored.png'
              alt='logo'
              height='64'
              className={`${scrolled ? 'd-none' : ''}`}
            />
            <img
              src='/img/logos/logo-white.png'
              alt='logo'
              height='64'
              className={`${scrolled ? '' : 'd-none'}`}
            />
          </a>
          <button
            className={`navbar-toggler ${scrolled ? 'scrolled' : ''}`}
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
              <li className='nav-item'>
                <a
                  className={`${styles.link} ${activeSection === 'hero' ? styles.active : ''} nav-link`}
                  href='#hero'
                >
                  الرئيسية
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`${styles.link} ${activeSection === 'about' ? styles.active : ''} nav-link`}
                  href='#about'
                >
                  من نحن
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`${styles.link} ${activeSection === 'team' ? styles.active : ''} nav-link`}
                  href='#team'
                >
                  فريقنا
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`${styles.link} ${activeSection === 'news' ? styles.active : ''} nav-link`}
                  href='#news'
                >
                  آخر الأخبار
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`${styles.link} ${activeSection === 'articles' ? styles.active : ''} nav-link`}
                  href='#articles'
                >
                  المقالات
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`${styles.link} ${activeSection === 'contact' ? styles.active : ''} nav-link`}
                  href='#contact'
                >
                  تواصل معنا
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`${styles.link} ${activeSection === 'partners' ? styles.active : ''} nav-link`}
                  href='#partners'
                >
                  شركاؤنا
                </a>
              </li>
              <li className='nav-item dropdown'>
                <a
                  className={`${styles.link} nav-link dropdown-toggle`}
                  href='#'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  الصفحات الداخلية
                </a>
                <ul className='dropdown-menu'>
                  <li>
                    <Link to='/News' className='dropdown-item'>
                      الأخبار
                    </Link>
                  </li>
                  <li>
                    <Link to='/Articles' className='dropdown-item'>
                      المقالات
                    </Link>
                  </li>
                  <li>
                    <Link to='/Observatory' className='dropdown-item'>
                      المرصد
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
