import React from 'react'
import { useArticles, ArticlesProps } from './useArticles'
import styles from './Articles.module.scss'
import { Link } from 'react-router-dom'
import TitleSection from 'components/TitleSection/TitleSection'
import ArticleCard from 'components/ArticleCard/ArticleCard'

const Articles: React.FC<ArticlesProps> = (props: ArticlesProps) => {
  const { latestBlogsData } = useArticles(props)

  return (
    <section id='articles' className={styles.articles}>
      <TitleSection title='المقالات' />
      <div className='container'>
        <div className='row mb-4 mb-lg-5'>
          {latestBlogsData &&
            latestBlogsData.length &&
            latestBlogsData.map((blog, index) => {
              const { description } = blog
              let shortDescription = ''

              if (
                description.length > 0 &&
                description[0].children &&
                description[0].children.length > 0
              ) {
                const firstText = description[0].children[0].text
                shortDescription = `${firstText.substring(0, 100)}...`
              } else shortDescription = 'لا يوجد تفاصيل...'

              return (
                <div
                  key={`blog-${blog._id}-${index}`}
                  className='col-lg-4 mb-3 mb-lg-0'
                >
                  <ArticleCard
                    image={blog.image}
                    title={blog.title}
                    description={shortDescription}
                    link={`/ArticleDetails/${blog.slug}`}
                  />
                </div>
              )
            })}
        </div>
        <div className='row'>
          <div className='col-12 text-center'>
            <Link to='/Articles' className='btn btn-secondary px-5 py-2'>
              مشاهدة جميع المقالات
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Articles
