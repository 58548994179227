import React, { useEffect } from 'react'
import InnerLayout from 'components/InnerLayout/InnerLayout'
import InnerTitleSection from 'components/InnerTitleSection/InnerTitleSection'
import Indicators from './Indicators/Indicators'
import Reports from './Reports/Reports'

const Observatory: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <InnerLayout>
      <main>
        <InnerTitleSection
          title='المرصد'
          breadcrumbs={[
            {
              title: 'الرئيسية',
              link: '/',
            },
            {
              title: 'المرصد',
            },
          ]}
        />
        <Indicators />
        <Reports />
      </main>
    </InnerLayout>
  )
}

export default Observatory
