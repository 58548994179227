import React from 'react'
import { Link } from 'react-router-dom'
import { useFooter, FooterProps } from './useFooter'
import styles from './Footer.module.scss'

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  const { thisYear, socialMedia } = useFooter(props)

  return (
    <footer className={styles.footer}>
      <div className={`container ${styles['footer-top']}`}>
        <div className='row gy-4'>
          <div className={`col-md-6 ${styles['footer-about']}`}>
            <Link to='/' className='logo d-flex align-items-center'>
              <img src='/img/logos/logo-white.png' alt='logo' height='64' />
            </Link>
            <div className={`${styles['footer-contact']} pt-3`}>
              <p className={styles.paragraph}>بيروت، لبنان</p>
              <p className={`${styles.paragraph} mt-3`}>
                <strong>الهاتف:</strong>{' '}
                <a
                  href={`tel:${socialMedia && socialMedia[0].phone}`}
                  dir='ltr'
                  className='text-white text-decoration-none text-hover-secondary transition-0-4'
                >
                  {socialMedia && socialMedia[0].phone}
                </a>
              </p>
              <p className={styles.paragraph}>
                <strong>البريد الإلكتروني:</strong>{' '}
                <a
                  href={`mailto:${socialMedia && socialMedia[0].email}`}
                  className='text-white text-decoration-none text-hover-secondary transition-0-4'
                >
                  {socialMedia && socialMedia[0].email}
                </a>
              </p>
            </div>
            <div
              className={`${styles['social-links']} d-flex mt-4 mb-4 mb-lg-0`}
            >
              <a
                href={socialMedia ? socialMedia[0].twitterLink : '#'}
                className={styles.link}
              >
                <i className='bi bi-twitter-x'></i>
              </a>
              <a
                href={socialMedia ? socialMedia[0].fbLink : '#'}
                className={styles.link}
              >
                <i className='bi bi-facebook'></i>
              </a>
              <a
                href={socialMedia ? socialMedia[0].instaLink : '#'}
                className={styles.link}
              >
                <i className='bi bi-instagram'></i>
              </a>
              <a
                href={socialMedia ? socialMedia[0].linkedinLink : '#'}
                className={styles.link}
              >
                <i className='bi bi-linkedin'></i>
              </a>
            </div>
          </div>
          <div className={`col-6 col-md-3 ${styles['footer-links']}`}>
            <h4 className={styles.title}>انتقل إلى</h4>
            <ul className={styles.list}>
              <li className={styles.item}>
                <Link to='/' className={styles.link}>
                  الرئيسية
                </Link>
              </li>
              <li className={styles.item}>
                <Link to='/#about' className={styles.link}>
                  من نحن
                </Link>
              </li>
              <li className={styles.item}>
                <Link to='/#team' className={styles.link}>
                  فريقنا
                </Link>
              </li>
              <li className={styles.item}>
                <Link to='/#news' className={styles.link}>
                  آخر الأخبار
                </Link>
              </li>
              <li className={styles.item}>
                <Link to='/#articles' className={styles.link}>
                  المقالات
                </Link>
              </li>
              <li className={styles.item}>
                <Link to='/#contact' className={styles.link}>
                  تواصل معنا
                </Link>
              </li>
              <li className={styles.item}>
                <Link to='/#partners' className={styles.link}>
                  شركاؤنا
                </Link>
              </li>
            </ul>
          </div>
          <div className={`col-6 col-md-3 ${styles['footer-links']}`}>
            <h4 className={styles.title}>الصفحات الداخلية</h4>
            <ul className={styles.list}>
              <li className={styles.item}>
                <Link to='/News' className={styles.link}>
                  الأخبار
                </Link>
              </li>
              <li className={styles.item}>
                <Link to='/Articles' className={styles.link}>
                  المقالات
                </Link>
              </li>
              <li className={styles.item}>
                <Link to='/Observatory' className={styles.link}>
                  المرصد
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={`container ${styles.copyright} text-center mt-4`}>
        <p dir='ltr' className='mb-0'>
          © <span>Copyright {thisYear}</span>{' '}
          <strong className='px-1 sitename'>Wehdet Watan.</strong>
          <span>All Rights Reserved</span>
        </p>
        <div dir='ltr'>
          Powered By{' '}
          <a
            href='https://www.linkedin.com/company/candle-media-solutions/'
            target='_blank'
            rel='noreferrer'
            className='text-white fw-bold text-decoration-none text-hover-secondary transition-0-4'
          >
            Candle Media Solutions
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
