import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getSocialMedia } from 'actions/home.action'
import { RootState } from 'reducers/rootReducer'

export interface FooterProps {}

export const useFooter = (props: FooterProps) => {
  const dispatch = useAppDispatch()

  const socialMedia = useSelector((state: RootState) => state.home.socialMedia)

  const thisYear = new Date().getFullYear()

  useEffect(() => {
    if (!socialMedia) dispatch(getSocialMedia())
  }, [socialMedia])

  return {
    ...props,
    thisYear,
    socialMedia,
  }
}
