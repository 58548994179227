import React, { Fragment } from 'react'
import {
  useInnerTitleSection,
  InnerTitleSectionProps,
} from './useInnerTitleSection'
import { Link } from 'react-router-dom'
import styles from './InnerTitleSection.module.scss'

const InnerTitleSection: React.FC<InnerTitleSectionProps> = (
  props: InnerTitleSectionProps
) => {
  const { title, breadcrumbs } = useInnerTitleSection(props)

  return (
    <div className={styles['page-title']}>
      <div className='container d-lg-flex justify-content-between align-items-center'>
        <h1 className={`${styles['title']} mb-2 mb-lg-0`}>{title}</h1>
        <nav className={styles.breadcrumbs}>
          <ol className={styles.list}>
            {breadcrumbs?.length ? (
              breadcrumbs.map((breadcrumb, index) =>
                breadcrumb.link ? (
                  <li key={`breadcrumb-${index}`} className={styles.item}>
                    <Link to={breadcrumb.link} className={styles.link}>
                      {breadcrumb.title}
                    </Link>
                  </li>
                ) : (
                  <li key={`breadcrumb-${index}`} className={styles.item}>
                    <span className={styles.text}>{breadcrumb.title}</span>
                  </li>
                )
              )
            ) : (
              <Fragment />
            )}
          </ol>
        </nav>
      </div>
    </div>
  )
}

export default InnerTitleSection
