import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getAboutUs } from 'actions/home.action'
import { RootState } from 'reducers/rootReducer'

export interface AboutProps {}

export const useAbout = (props: AboutProps) => {
  const dispatch = useAppDispatch()

  const aboutUsData = useSelector((state: RootState) => state.home.aboutUsData)

  useEffect(() => {
    if (!aboutUsData) dispatch(getAboutUs())
  }, [aboutUsData])

  return {
    ...props,
    aboutUsData,
  }
}
