export interface FeatureCardProps {
  icon: string
  title: string
  subTitle: string
  description: { __html: string | TrustedHTML; } | undefined
}

export const useFeatureCard = (props: FeatureCardProps) => {
  return {
    ...props,
  }
}
