import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getLatestBlogs } from 'actions/blogs.action'
import { RootState } from 'reducers/rootReducer'

export interface ArticlesProps {}

export const useArticles = (props: ArticlesProps) => {
  const dispatch = useAppDispatch()

  const latestBlogsData = useSelector(
    (state: RootState) => state.blogs.latestBlogsData
  )

  useEffect(() => {
    if (!latestBlogsData) dispatch(getLatestBlogs())
  }, [latestBlogsData])

  return {
    ...props,
    latestBlogsData,
  }
}
