import React, { Fragment } from 'react'
import { useTitleSection, TitleSectionProps } from './useTitleSection'
import styles from './TitleSection.module.scss'

const TitleSection: React.FC<TitleSectionProps> = (
  props: TitleSectionProps
) => {
  const { title, description } = useTitleSection(props)

  return (
    <div className={`container ${styles['section-title']}`}>
      <h2 className={styles.title}>{title}</h2>
      {description ? (
        <p className={styles.description}>{description}</p>
      ) : (
        <Fragment />
      )}
    </div>
  )
}

export default TitleSection
