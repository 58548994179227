import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getOurTeam } from 'actions/home.action'
import { RootState } from 'reducers/rootReducer'

export interface TeamProps {}

export const useTeam = (props: TeamProps) => {
  const dispatch = useAppDispatch()

  const ourTeamData = useSelector((state: RootState) => state.home.ourTeamData)

  useEffect(() => {
    if (!ourTeamData) dispatch(getOurTeam())
  }, [ourTeamData])

  return {
    ...props,
    ourTeamData,
  }
}
