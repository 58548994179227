import React from 'react'
import { Link } from 'react-router-dom'
import { useHeader, HeaderProps } from './useHeader'
import styles from './Header.module.scss'

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const {} = useHeader(props)

  return (
    <header>
      <nav className={`${styles.navbar} navbar fixed-top navbar-expand-lg`}>
        <div className='container'>
          <Link className='navbar-brand' to='/'>
            <img src='/img/logos/logo-white.png' alt='logo' height='64' />
          </Link>
          <button
            className='navbar-toggler scrolled'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
              <li className='nav-item'>
                <Link className={`${styles.link} nav-link`} to='/'>
                  الرئيسية
                </Link>
              </li>
              <li className='nav-item'>
                <Link className={`${styles.link} nav-link`} to='/News'>
                  الأخبار
                </Link>
              </li>
              <li className='nav-item'>
                <Link className={`${styles.link} nav-link`} to='/Articles'>
                  المقالات
                </Link>
              </li>
              <li className='nav-item'>
                <Link className={`${styles.link} nav-link`} to='/Observatory'>
                  المرصد
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
