import React from 'react'
import { useArticleDetails, ArticleDetailsProps } from './useArticleDetails'
import blockContentToHtml from '@sanity/block-content-to-html'
import styles from './ArticleDetails.module.scss'
import InnerLayout from 'components/InnerLayout/InnerLayout'
import InnerTitleSection from 'components/InnerTitleSection/InnerTitleSection'
import AuthorCard from 'components/AuthorCard/AuthorCard'
import LatestArticles from './LatestArticles/LatestArticles'
import Tags from './Tags/Tags'

const ArticleDetails: React.FC<ArticleDetailsProps> = (
  props: ArticleDetailsProps
) => {
  const { blogDetails, latestBlogs, tags } = useArticleDetails(props)

  return (
    <InnerLayout>
      <main>
        <InnerTitleSection
          title='تفاصيل المقالة'
          breadcrumbs={[
            {
              title: 'الرئيسية',
              link: '/',
            },
            {
              title: 'المقالات',
              link: '/Articles',
            },
            {
              title: 'تفاصيل المقالة',
            },
          ]}
        />
        <article className={styles['article-details']}>
          <div className='container'>
            {blogDetails && blogDetails.length && (
              <div className='row'>
                <div className='col-lg-8 col-xl-9'>
                  <img src={blogDetails[0].image} className={styles.image} />
                  <div className={styles.content}>
                    <h1 className={styles.title}>{blogDetails[0].title}</h1>
                    <div className={styles.date}>
                      <i className='bi bi-clock me-2'></i>
                      <time dir='ltr'>
                        {`${blogDetails[0]._updatedAt.split('T')[0]} ${blogDetails[0]._updatedAt.split('T')[1].slice(0, -1)}`}
                      </time>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: Array.isArray(blogDetails[0].description)
                          ? blockContentToHtml({
                              blocks: blogDetails[0].description,
                            })
                          : `<p>${blogDetails[0].description}</p>`,
                      }}
                    ></div>
                  </div>
                </div>
                <div className='col-lg-4 col-xl-3'>
                  <AuthorCard
                    name={blogDetails[0].author.fullName}
                    description={blogDetails[0].author.description}
                    url={
                      blogDetails[0].author.url
                        ? blogDetails[0].author.url
                        : null
                    }
                    photo={blogDetails[0].author.photo}
                  />
                  <LatestArticles blogs={latestBlogs} />
                  <Tags tags={tags} />
                </div>
              </div>
            )}
          </div>
        </article>
      </main>
    </InnerLayout>
  )
}

export default ArticleDetails
