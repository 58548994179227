import React, { Fragment } from 'react'
import { useInnerLayout, InnerLayoutProps } from './useInnerLayout'
import Header from './Header/Header'
import Footer from './Footer/Footer'

const InnerLayout: React.FC<InnerLayoutProps> = (props: InnerLayoutProps) => {
  const { children } = useInnerLayout(props)

  return (
    <Fragment>
      <Header />
      {children}
      <Footer />
    </Fragment>
  )
}

export default InnerLayout
