import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import {
  getBlogDetails,
  getLatestBlogs,
  clearBlogDetails,
} from 'actions/blogs.action'
import { RootState } from 'reducers/rootReducer'
import { IBlogs } from 'models/Blogs/IBlogs'
import ITag from 'models/common/ITag'

export interface ArticleDetailsProps {}

export const useArticleDetails = (props: ArticleDetailsProps) => {
  const dispatch = useAppDispatch()

  const { slug } = useParams<{ slug: string }>()

  const [latestBlogs, setLatestBlogs] = useState<IBlogs[]>([])
  const [tags, setTags] = useState<ITag[]>([])

  const blogDetails = useSelector((state: RootState) => state.blogs.blogDetails)
  const latestBlogsData = useSelector(
    (state: RootState) => state.blogs.latestBlogsData
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!blogDetails && slug) dispatch(getBlogDetails(slug))
  }, [blogDetails, slug])

  useEffect(() => {
    if (!latestBlogsData) dispatch(getLatestBlogs())

    if (latestBlogsData && latestBlogsData.length) {
      const latestBlogsTemp = latestBlogsData.filter(
        (blog) => blog.slug !== slug
      )

      setLatestBlogs(latestBlogsTemp)
    }
  }, [latestBlogsData, slug])

  useEffect(() => {
    if (blogDetails && blogDetails.length) setTags(blogDetails[0].tags)
  }, [blogDetails])

  useEffect(() => {
    return () => {
      dispatch(clearBlogDetails())
    }
  }, [dispatch, slug])

  return {
    ...props,
    blogDetails,
    latestBlogs,
    tags,
  }
}
