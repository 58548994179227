import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import {
  getPaginatedNews,
  getNewsCount,
  getNewsCategories,
} from 'actions/news.action'
import { RootState } from 'reducers/rootReducer'

export interface NewsListingProps {}

export const useNewsListing = (props: NewsListingProps) => {
  const dispatch = useAppDispatch()

  enum InitialValues {
    From = 0,
    To = 5,
  }

  const [from, setFrom] = useState(InitialValues.From)
  const [to, setTo] = useState(InitialValues.To)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')

  const paginatedNewsData = useSelector(
    (state: RootState) => state.news.paginatedNewsData
  )
  const newsCategories = useSelector(
    (state: RootState) => state.news.newsCategories
  )
  const isLoading = useSelector(
    (state: RootState) => state.news.isLoadingPaginatedNews
  )
  const newsCount = useSelector((state: RootState) => state.news.newsCount)

  const handleLoadMore = () => {
    setFrom(from + 6)
    setTo(to + 6)
  }

  const handleSearch = () => {
    setFrom(InitialValues.From)
    setTo(InitialValues.To)
    dispatch(
      getPaginatedNews(
        InitialValues.From,
        InitialValues.To,
        searchTerm,
        selectedCategory
      )
    )
  }

  useEffect(() => {
    if (!paginatedNewsData) {
      dispatch(
        getPaginatedNews(
          InitialValues.From,
          InitialValues.To,
          searchTerm,
          selectedCategory
        )
      )
      dispatch(getNewsCount())
      dispatch(getNewsCategories())
    }
  }, [paginatedNewsData])

  useEffect(() => {
    if (from !== InitialValues.From)
      dispatch(getPaginatedNews(from, to, searchTerm, selectedCategory))
  }, [from, to])

  return {
    ...props,
    searchTerm,
    selectedCategory,
    paginatedNewsData,
    newsCount,
    newsCategories,
    isLoading,
    setSearchTerm,
    setSelectedCategory,
    handleLoadMore,
    handleSearch,
  }
}
