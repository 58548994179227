export interface ContactCardProps {
  icon: string
  title: string
  description: string
  isPhone?: boolean
  isEmail?: boolean
}

export const useContactCard = (props: ContactCardProps) => {
  return {
    ...props,
  }
}
