export interface AuthorCardProps {
  name: string
  description: string
  url: string | null
  photo: string
}

export const useAuthorCard = (props: AuthorCardProps) => {
  return {
    ...props,
  }
}
