import React from 'react'
import { useIndicatorCard, IndicatorCardProps } from './useIndicatorCard'
import styles from './IndicatorCard.module.scss'

const IndicatorCard: React.FC<IndicatorCardProps> = (
  props: IndicatorCardProps
) => {
  const { name, scale, description, status, Status } = useIndicatorCard(props)

  return (
    <div
      className={`${styles['indicator-card']} ${status === Status.Up ? styles['up'] : status === Status.Down ? styles['down'] : styles['stable']}`}
    >
      {status === Status.Up ? (
        <i className='bi bi-graph-up-arrow'></i>
      ) : status === Status.Down ? (
        <i className='bi bi-graph-down-arrow'></i>
      ) : (
        <i className='bi bi-arrow-right'></i>
      )}
      <h4>{name}</h4>
      <h5>{scale}</h5>
      <p>{description}</p>
    </div>
  )
}

export default IndicatorCard
