import { INews } from 'models/News/INews'

export interface LatestNewsProps {
  news: INews[] | null
}

export const useLatestNews = (props: LatestNewsProps) => {
  return {
    ...props,
  }
}
