export const ALL_ENDPOINTS = {
  All: {
    GetAll: 'data/query/production?query=*',
  },
  Home: {
    GetSlider:
      'data/query/production?query=*[_type == "slider"]{_id, title, description, button_title, button_link, "image_BG": image_BG.asset->url}',
    GetAboutUs:
      'data/query/production?query=*[_type == "aboutUs"]{ _id, "image": photo.asset->url,ourHistory {title, description},ourVision {title,description},ourMission {title,subtitle,description},ourValues {title, subtitle,description}}',
    GetOurTeam:
      'data/query/production?query=*[_type == "ourTeam"]{ _id, name, position, description, "personalPhotoUrl": personalPhoto.asset->url, twitterLink, fbLink, instaLink, linkedinLink }',
    GetOurPartner:
      'data/query/production?query=*[_type=="ourPartners"]{_id, partner, url, "logo": logo.asset->url}',
    GetSocialMedia:
      'data/query/production?query=*[_type == "socialMedia"]{ _id, email, phone, fbLink, twitterLink, instaLink, linkedinLink}',
  },
  ContactForm: {
    Post: 'data/mutate/production',
  },
  News: {
    GetNewsCount: 'data/query/production?query=*[_type == "news"]{_id}',
    GetLatestNews:
      'data/query/production?query=*[_type == "news"] | order(_updatedAt desc)[0..2]{ _id, _updatedAt, title, "slug": slug.current, "image": image.asset->url,content}',
    GetPaginatedNews: 'data/query/production',
    GetNewsDetailsPrefix:
      'data/query/production?query=*[_type == "news"][slug.current == "',
    GetNewsDetailsSuffix:
      '"]{ _id, _updatedAt, title, "slug": slug.current, author->{ _id, fullName}, category->{ _id,name }, "image": image.asset->url,content, tags[]->{ _id,tag}}',
    GetNewsCategory:
      'data/query/production?query=*[_type == "newsCategory"]{ _id, name}',
  },
  Blogs: {
    GetBlogsCount: 'data/query/production?query=*[_type == "blog"]{_id}',
    GetLatestBlogs:
      'data/query/production?query=*[_type == "blog"] | order(_updatedAt desc)[0..2]{ _id, _updatedAt, title, "slug": slug.current, "image": image.asset->url,description}',
    GetPaginatedBlogs: 'data/query/production',
    GetBlogDetailsPrefix:
      'data/query/production?query=*[_type == "blog"][slug.current == "',
    GetBlogDetailsSuffix:
      '"]{ _id, _updatedAt, title, "slug": slug.current, author->{ _id, fullName, url, description, "photo": photo.asset->url}, category->{ _id,name }, "image": image.asset->url,description, tags[]->{ _id,tag}}',
    GetBlogsCategory:
      'data/query/production?query=*[_type == "blogCategory"]{_id, name}',
  },
  Observatory: {
    GetIndicators:
      'data/query/production?query=*[_type=="indicator"]{_id, indicatorName, indicatorScale, upOrDown, description}',
    GetReports:
      'data/query/production?query=*[_type=="quarterlyObservatoryReport"]{_id, title, author->{ _id, fullName, "photo": photo.asset->url}, description, tags[]->{_id, tag}, "report": report.asset->url}',
  },
}
