export interface InnerTitleSectionProps {
  title: string
  breadcrumbs?: Breadcrumb[]
}

interface Breadcrumb {
  title: string
  link?: string
}

export const useInnerTitleSection = (props: InnerTitleSectionProps) => {
  return {
    ...props,
  }
}
