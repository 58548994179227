import ITag from 'models/common/ITag'

export interface TagsProps {
  tags: ITag[] | null
}

export const useTags = (props: TagsProps) => {
  return {
    ...props,
  }
}
