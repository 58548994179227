import React from 'react'
import { useReports, ReportsProps } from './useReports'
import TitleSection from 'components/TitleSection/TitleSection'
import ReportCard from 'components/ReportCard/ReportCard'

const Reports: React.FC<ReportsProps> = (props: ReportsProps) => {
  const { reportsData } = useReports(props)

  return (
    <section className='py-5' style={{ backgroundColor: '#f8fbfc' }}>
      <div className='container'>
        <TitleSection title='التقارير' />
        <div className='row'>
          {reportsData &&
            reportsData.length &&
            reportsData.map((report, index) => (
              <div
                key={`report-${report._id}-${index}`}
                className='col-md-6 col-lg-4 col-xl-3 mb-4'
              >
                <ReportCard
                  title={report.title}
                  description={report.description}
                  report={report.report}
                  author={report.author.fullName}
                  authorPicture={report.author.photo}
                  tags={report.tags}
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Reports
