import React from 'react'
import { useArticlesListing, ArticlesListingProps } from './useArticlesListing'
import ArticleCard from 'components/ArticleCard/ArticleCard'

const ArticlesListing: React.FC<ArticlesListingProps> = (
  props: ArticlesListingProps
) => {
  const {
    searchTerm,
    selectedCategory,
    paginatedBlogsData,
    blogsCount,
    blogCategories,
    isLoading,
    setSearchTerm,
    setSelectedCategory,
    handleLoadMore,
    handleSearch,
  } = useArticlesListing(props)

  return (
    <section>
      <div className='container pt-5'>
        <div className='row mb-4'>
          <div className='col-12 col-md-4 mb-2 mb-lg-0'>
            <input
              type='search'
              className='form-control'
              placeholder='العنوان...'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className='col-12 col-md-4 mb-2 mb-lg-0'>
            <select
              className='form-select'
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value='0'>الفئة</option>
              {blogCategories &&
                blogCategories.length &&
                blogCategories.map((category, index) => (
                  <option
                    key={`category-${category._id}-${index}`}
                    value={category._id}
                  >
                    {category.name}
                  </option>
                ))}
            </select>
          </div>
          <div className='col-12 col-md-4'>
            <button
              type='button'
              className='btn btn-secondary fw-bold w-100'
              onClick={() => handleSearch()}
            >
              <i className='bi bi-search me-2'></i>بحث
            </button>
          </div>
        </div>
        <div className='row'>
          {paginatedBlogsData && paginatedBlogsData.length ? (
            paginatedBlogsData.map((blog, index) => {
              const { description } = blog
              let shortDescription = ''

              if (
                description.length > 0 &&
                description[0].children &&
                description[0].children.length > 0
              ) {
                const firstText = description[0].children[0].text
                shortDescription = `${firstText.substring(0, 100)}...`
              } else shortDescription = 'لا يوجد تفاصيل...'

              return (
                <div
                  key={`blog-${blog._id}-${index}`}
                  className='col-12 col-md-6 col-lg-4 mb-3'
                >
                  <ArticleCard
                    image={blog.image}
                    title={blog.title}
                    description={shortDescription}
                    link={`/ArticleDetails/${blog.slug}`}
                  />
                </div>
              )
            })
          ) : (
            <div className='col-12 text-center fs-4 fw-bold'>
              لا يوجد بيانات
            </div>
          )}
        </div>
        <div className='row mt-4 mb-5'>
          <div className='col-12 text-center'>
            <button
              type='button'
              className='btn btn-secondary px-5 py-2'
              style={{
                display:
                  paginatedBlogsData &&
                  paginatedBlogsData.length &&
                  blogsCount > paginatedBlogsData.length
                    ? 'initial'
                    : 'none',
              }}
              disabled={isLoading}
              onClick={() => handleLoadMore()}
            >
              المزيد
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ArticlesListing
