import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import {
  getPaginatedBlogs,
  getBlogsCount,
  getBlogCategories,
} from 'actions/blogs.action'
import { RootState } from 'reducers/rootReducer'

export interface ArticlesListingProps {}

export const useArticlesListing = (props: ArticlesListingProps) => {
  const dispatch = useAppDispatch()

  enum InitialValues {
    From = 0,
    To = 5,
  }

  const [from, setFrom] = useState(InitialValues.From)
  const [to, setTo] = useState(InitialValues.To)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')

  const paginatedBlogsData = useSelector(
    (state: RootState) => state.blogs.paginatedBlogsData
  )
  const blogCategories = useSelector(
    (state: RootState) => state.blogs.blogCategories
  )
  const isLoading = useSelector(
    (state: RootState) => state.blogs.isLoadingPaginatedBlogs
  )
  const blogsCount = useSelector((state: RootState) => state.blogs.blogsCount)

  const handleLoadMore = () => {
    setFrom(from + 6)
    setTo(to + 6)
  }

  const handleSearch = () => {
    setFrom(InitialValues.From)
    setTo(InitialValues.To)
    dispatch(
      getPaginatedBlogs(
        InitialValues.From,
        InitialValues.To,
        searchTerm,
        selectedCategory
      )
    )
  }

  useEffect(() => {
    if (!paginatedBlogsData) {
      dispatch(
        getPaginatedBlogs(
          InitialValues.From,
          InitialValues.To,
          searchTerm,
          selectedCategory
        )
      )
      dispatch(getBlogsCount())
      dispatch(getBlogCategories())
    }
  }, [paginatedBlogsData])

  useEffect(() => {
    if (from !== InitialValues.From)
      dispatch(getPaginatedBlogs(from, to, searchTerm, selectedCategory))
  }, [from, to])

  return {
    ...props,
    searchTerm,
    selectedCategory,
    paginatedBlogsData,
    blogsCount,
    blogCategories,
    isLoading,
    setSearchTerm,
    setSelectedCategory,
    handleLoadMore,
    handleSearch,
  }
}
