import React from 'react'
import { useLatestNews, LatestNewsProps } from './useLatestNews'
import styles from './LatestNews.module.scss'
import LatestPost from 'components/LatestPost/LatestPost'

const LatestNews: React.FC<LatestNewsProps> = (props: LatestNewsProps) => {
  const { news } = useLatestNews(props)

  return (
    <div className={`${styles['latest-news']} mt-4 mt-lg-0`}>
      <h4 className={styles.title}>آخر الأخبار</h4>
      {news &&
        news.length &&
        news.map((news, index) => (
          <LatestPost
            key={`news-${news._id}-${index}`}
            image={news.image}
            title={news.title}
            link={`/NewsDetails/${news.slug}`}
            date={news._updatedAt.split('T')[0]}
          />
        ))}
    </div>
  )
}

export default LatestNews
