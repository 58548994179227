import React from 'react'
import { useNewsCard, NewsCardProps } from './useNewsCard'
import styles from './NewsCard.module.scss'
import { Link } from 'react-router-dom'

const NewsCard: React.FC<NewsCardProps> = (props: NewsCardProps) => {
  const { image, title, description, link, finalDate } = useNewsCard(props)

  return (
    <div className={styles['news-card']}>
      <Link to={link} className={styles.link}>
        <div className={styles['news-card-image-container']}>
          <img
            src={image}
            className={styles['news-card-image']}
            alt='news media'
          />
          <span className={styles['news-card-date']}>{finalDate}</span>
        </div>
        <div className={styles['news-card-content']}>
          <h3 className={styles['news-card-title']}>{title}</h3>
          <p className={`${styles.description} mt-4`}>{description}</p>
          <hr className={styles['news-card-splitter']} />
          <div className={styles['news-card-link']}>
            <span className={styles['news-card-link-text']}>اقرأ المزيد</span>
            <i className='bi bi-arrow-left'></i>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default NewsCard
