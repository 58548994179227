import React from 'react'
import { useNews, NewsProps } from './useNews'
import styles from './News.module.scss'
import { Link } from 'react-router-dom'
import TitleSection from 'components/TitleSection/TitleSection'
import NewsCard from 'components/NewsCard/NewsCard'

const News: React.FC<NewsProps> = (props: NewsProps) => {
  const { latestNewsData } = useNews(props)

  return (
    <section id='news' className={styles.news}>
      <TitleSection title='آخر الأخبار' />
      <div className='container'>
        <div className='row mb-4 mb-lg-5'>
          {latestNewsData &&
            latestNewsData.length &&
            latestNewsData.map((news, index) => {
              const { content } = news
              let shortDescription = ''

              if (
                content.length > 0 &&
                content[0].children &&
                content[0].children.length > 0
              ) {
                const firstText = content[0].children[0].text
                shortDescription = `${firstText.substring(0, 100)}...`
              } else shortDescription = 'لا يوجد تفاصيل...'

              return (
                <div
                  key={`news-${news._id}-${index}`}
                  className='col-lg-4 mb-3 mb-lg-0'
                >
                  <NewsCard
                    image={news.image}
                    title={news.title}
                    description={shortDescription}
                    link={`/NewsDetails/${news.slug}`}
                    date={news._updatedAt.split('T')[0]}
                  />
                </div>
              )
            })}
        </div>
        <div className='row'>
          <div className='col-12 text-center'>
            <Link to='/News' className='btn btn-secondary px-5 py-2'>
              مشاهدة جميع الأخبار
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default News
