import React, { Fragment } from 'react'
import { useContact, ContactProps } from './useContact'
import styles from './Contact.module.scss'
import TitleSection from 'components/TitleSection/TitleSection'
import ContactCard from 'components/ContactCard/ContactCard'

const Contact: React.FC<ContactProps> = (props: ContactProps) => {
  const {
    socialMedia,
    contactFormData,
    isLoading,
    isSendContactMessageSuccess,
    isSendContactMessageFailed,
    handleControllerChange,
    handleFormSubmit,
  } = useContact(props)

  return (
    <section id='contact' className={styles.contact}>
      <TitleSection title='تواصل معنا' />
      <div className='container'>
        <div className='row gy-4'>
          <div className='col-lg-6'>
            <ContactCard
              icon='bi bi-geo-alt'
              title='الموقع'
              description='بيروت، لبنان'
            />
          </div>
          <div className='col-lg-3 col-md-6'>
            <ContactCard
              icon='bi bi-telephone'
              title='اتصل بنا'
              description={socialMedia ? socialMedia[0].phone : ''}
              isPhone
            />
          </div>
          <div className='col-lg-3 col-md-6'>
            <ContactCard
              icon='bi bi-envelope'
              title='البريد الإلكتروني'
              description={socialMedia ? socialMedia[0].email : ''}
              isEmail
            />
          </div>
        </div>
        <div className='row gy-4 mt-1'>
          <div className='col-lg-6'>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d48389.78314118045!2d-74.006138!3d40.710059!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a22a3bda30d%3A0xb89d1fe6bc499443!2sDowntown%20Conference%20Center!5e0!3m2!1sen!2sus!4v1676961268712!5m2!1sen!2sus'
              frameBorder='0'
              title='Address'
              style={{
                border: 0,
                width: '100%',
                height: '400px',
                borderRadius: '10px',
              }}
              allowFullScreen
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
            ></iframe>
          </div>
          <div className='col-lg-6'>
            <form
              className={styles['contact-form']}
              onSubmit={handleFormSubmit}
            >
              <div className='row gy-4'>
                <div className='col-md-6'>
                  <input
                    type='text'
                    name='name'
                    className='form-control'
                    placeholder='الإسم'
                    value={contactFormData.name}
                    onChange={handleControllerChange}
                    required
                  />
                </div>
                <div className='col-md-6 '>
                  <input
                    type='email'
                    className='form-control'
                    name='email'
                    placeholder='البريد الإلكتروني'
                    value={contactFormData.email}
                    onChange={handleControllerChange}
                    required
                  />
                </div>
                <div className='col-md-12'>
                  <input
                    type='text'
                    className='form-control'
                    name='subject'
                    placeholder='الموضوع'
                    value={contactFormData.subject}
                    onChange={handleControllerChange}
                    required
                  />
                </div>
                <div className='col-md-12'>
                  <textarea
                    className='form-control'
                    name='message'
                    rows={6}
                    placeholder='نص الرسالة...'
                    value={contactFormData.message}
                    onChange={handleControllerChange}
                    required
                  ></textarea>
                </div>
                {isSendContactMessageFailed ? (
                  <div className='col-md-12'>
                    <div className='alert alert-danger' role='alert'>
                      حصل خطأ ما، الرجاء إعادة المحاولة لاحقا
                    </div>
                  </div>
                ) : (
                  <Fragment />
                )}
                {isSendContactMessageSuccess ? (
                  <div className='col-md-12'>
                    <div className='alert alert-success' role='alert'>
                      تم استلام رسالتك بنجاح
                    </div>
                  </div>
                ) : (
                  <Fragment />
                )}
                <div className='col-md-12 text-center'>
                  <button
                    type='submit'
                    className='btn btn-secondary px-5 py-2'
                    disabled={isLoading}
                  >
                    {isLoading ? 'جاري الإرسال...' : 'ارسال'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
