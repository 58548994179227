import makeRequest from 'utils/makeRequest'
import { ALL_ENDPOINTS } from 'config/apiEndpoints'
import IIndicatorResponse from 'models/Observatory/IIndicator'
import IReportResponse from 'models/Observatory/IReport'

export const observatoryService = {
  getIndicators: async (): Promise<IIndicatorResponse> => {
    try {
      const response: IIndicatorResponse = await makeRequest(
        'GET',
        ALL_ENDPOINTS.Observatory.GetIndicators
      )

      return response
    } catch (error) {
      throw new Error('Get indicators data failed')
    }
  },
  getReports: async (): Promise<IReportResponse> => {
    try {
      const response: IReportResponse = await makeRequest(
        'GET',
        ALL_ENDPOINTS.Observatory.GetReports
      )

      return response
    } catch (error) {
      throw new Error('Get reports data failed')
    }
  },
}
