import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getIndicators } from 'actions/observatory.action'
import { RootState } from 'reducers/rootReducer'

export interface IndicatorsProps {}

export const useIndicators = (props: IndicatorsProps) => {
  const dispatch = useAppDispatch()

  const indicatorsData = useSelector(
    (state: RootState) => state.observatory.indicatorsData
  )

  useEffect(() => {
    if (!indicatorsData) dispatch(getIndicators())
  }, [])

  return {
    ...props,
    indicatorsData,
  }
}
