import { createSlice } from '@reduxjs/toolkit'
import { IBlogs } from 'models/Blogs/IBlogs'
import { IBlogCategory } from 'models/Blogs/IBlogCategory'
import { IBlogDetails } from 'models/Blogs/IBlogDetails'

export interface BlogsState {
  isLoading: boolean
  isLoadingPaginatedBlogs: boolean
  isGetLatestBlogsSuccess: boolean
  isGetBlogsCountSuccess: boolean
  isGetBlogCategoriesSuccess: boolean
  isGetBlogDetailsSuccess: boolean
  latestBlogsData: IBlogs[] | null
  paginatedBlogsData: IBlogs[] | null
  blogCategories: IBlogCategory[] | null
  blogDetails: IBlogDetails[] | null
  blogsCount: number
}

const initialState: BlogsState = {
  isLoading: false,
  isLoadingPaginatedBlogs: false,
  isGetLatestBlogsSuccess: false,
  isGetBlogsCountSuccess: false,
  isGetBlogCategoriesSuccess: false,
  isGetBlogDetailsSuccess: false,
  latestBlogsData: null,
  paginatedBlogsData: null,
  blogCategories: null,
  blogDetails: null,
  blogsCount: 0,
}

const blogsSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
    },
    paginatedBlogsRequest: (state) => {
      state.isLoadingPaginatedBlogs = true
    },
    clearBlogDetails: (state) => {
      state.isGetBlogDetailsSuccess = false
      state.blogDetails = null
    },
    getLatestBlogs(state, action) {
      state.isLoading = false
      state.isGetLatestBlogsSuccess = true
      state.latestBlogsData = action.payload.latestBlogsData.result
    },
    getBlogsCount(state, action) {
      state.isLoading = false
      state.isGetBlogsCountSuccess = true
      state.blogsCount = action.payload.blogsCount.result.length
    },
    getBlogCategories(state, action) {
      state.isLoading = false
      state.isGetBlogCategoriesSuccess = true
      state.blogCategories = action.payload.blogCategories.result.shift()
    },
    getBlogDetails(state, action) {
      state.isLoading = false
      state.isGetBlogDetailsSuccess = true
      state.blogDetails = action.payload.blogDetails.result
    },
    getPaginatedBlogsSearch(state, action) {
      state.isLoadingPaginatedBlogs = false
      state.paginatedBlogsData = action.payload.paginatedBlogs.result
    },
    getPaginatedBlogsLoadMore(state, action) {
      state.isLoadingPaginatedBlogs = false

      const existingIds = new Set(
        state.paginatedBlogsData?.map((blogs) => blogs._id) || []
      )
      const newUniqueBlogs = action.payload.paginatedBlogs.result.filter(
        (blogs: any) => !existingIds.has(blogs._id)
      )

      state.paginatedBlogsData = [
        ...(state.paginatedBlogsData || []),
        ...newUniqueBlogs,
      ]
    },
  },
})

export const {
  apiRequest,
  apiFailure,
  paginatedBlogsRequest,
  clearBlogDetails,
  getLatestBlogs,
  getBlogsCount,
  getPaginatedBlogsSearch,
  getPaginatedBlogsLoadMore,
  getBlogCategories,
  getBlogDetails,
} = blogsSlice.actions

export default blogsSlice.reducer
