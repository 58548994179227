import { createSlice } from '@reduxjs/toolkit'
import { IIndicator } from 'models/Observatory/IIndicator'
import { IReport } from 'models/Observatory/IReport'

export interface ObservatoryState {
  isLoading: boolean
  isGetIndicatorsSuccess: boolean
  isGetReportsSuccess: boolean
  indicatorsData: IIndicator[] | null
  reportsData: IReport[] | null
}

const initialState: ObservatoryState = {
  isLoading: false,
  isGetIndicatorsSuccess: false,
  isGetReportsSuccess: false,
  indicatorsData: null,
  reportsData: null,
}

const observatorySlice = createSlice({
  name: 'observatory',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
    },
    getIndicators(state, action) {
      state.isLoading = false
      state.isGetIndicatorsSuccess = true
      state.indicatorsData = action.payload.indicatorsData.result
    },
    getReports(state, action) {
      state.isLoading = false
      state.isGetReportsSuccess = true
      state.reportsData = action.payload.reportsData.result
    },
  },
})

export const { apiRequest, apiFailure, getIndicators, getReports } =
  observatorySlice.actions

export default observatorySlice.reducer
