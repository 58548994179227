import { createSlice } from '@reduxjs/toolkit'
import { ISlider } from 'models/Home/ISlider'
import { IAboutUs } from 'models/Home/IAboutUs'
import { IOurTeam } from 'models/Home/IOurTeam'
import { IOurPartner } from 'models/Home/IOurPartner'
import { ISocialMedia } from 'models/Home/ISocialMedia'

export interface HomeState {
  isLoading: boolean
  isLoadingContactMessage: boolean
  isGetSliderSuccess: boolean
  isGetAboutUsSuccess: boolean
  isGetOurTeamSuccess: boolean
  isGetOurPartnerSuccess: boolean
  isGetSocialMediaSuccess: boolean
  isSendContactMessageSuccess: boolean
  isSendContactMessageFailed: boolean
  sliderData: ISlider[] | null
  aboutUsData: IAboutUs[] | null
  ourTeamData: IOurTeam[] | null
  ourPartnerData: IOurPartner[] | null
  socialMedia: ISocialMedia[] | null
  contactMessageResponse: any | null
}

const initialState: HomeState = {
  isLoading: false,
  isLoadingContactMessage: false,
  isGetSliderSuccess: false,
  isGetAboutUsSuccess: false,
  isGetOurTeamSuccess: false,
  isGetOurPartnerSuccess: false,
  isGetSocialMediaSuccess: false,
  isSendContactMessageSuccess: false,
  isSendContactMessageFailed: false,
  sliderData: null,
  aboutUsData: null,
  ourTeamData: null,
  ourPartnerData: null,
  socialMedia: null,
  contactMessageResponse: null,
}

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
    },
    sendContactMessageRequest: (state) => {
      state.isLoadingContactMessage = true
      state.isSendContactMessageFailed = false
      state.isSendContactMessageSuccess = false
    },
    getSlider(state, action) {
      state.isLoading = false
      state.isGetSliderSuccess = true
      state.sliderData = action.payload.sliderData.result
    },
    getAboutUs(state, action) {
      state.isLoading = false
      state.isGetAboutUsSuccess = true
      state.aboutUsData = action.payload.aboutUsData.result
    },
    getOurTeam(state, action) {
      state.isLoading = false
      state.isGetOurTeamSuccess = true
      state.ourTeamData = action.payload.ourTeamData.result
    },
    getOurPartner(state, action) {
      state.isLoading = false
      state.isGetOurPartnerSuccess = true
      state.ourPartnerData = action.payload.ourPartnerData.result
    },
    getSocialMedia(state, action) {
      state.isLoading = false
      state.isGetSocialMediaSuccess = true
      state.socialMedia = action.payload.socialMedia.result
    },
    sendContactMessage(state, action) {
      state.isLoadingContactMessage = false
      state.isSendContactMessageSuccess = true
      state.isSendContactMessageFailed = false
      state.contactMessageResponse = action.payload.contactMessageResponse
    },
    sendContactMessageFailed(state) {
      state.isSendContactMessageFailed = true
    },
  },
})

export const {
  apiRequest,
  apiFailure,
  getSlider,
  getAboutUs,
  getOurTeam,
  getOurPartner,
  getSocialMedia,
  sendContactMessage,
  sendContactMessageRequest,
  sendContactMessageFailed,
} = homeSlice.actions

export default homeSlice.reducer
