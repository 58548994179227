import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getReports } from 'actions/observatory.action'
import { RootState } from 'reducers/rootReducer'

export interface ReportsProps {}

export const useReports = (props: ReportsProps) => {
  const dispatch = useAppDispatch()

  const reportsData = useSelector(
    (state: RootState) => state.observatory.reportsData
  )

  useEffect(() => {
    if (!reportsData) dispatch(getReports())
  }, [])

  return {
    ...props,
    reportsData,
  }
}
