import makeRequest from 'utils/makeRequest'
import { ALL_ENDPOINTS } from 'config/apiEndpoints'
import INewsResponse from 'models/News/INews'
import INewsCountResponse from 'models/News/INewsCount'
import INewsCategoryResponse from 'models/News/INewsCategory'
import INewsDetailsResponse from 'models/News/INewsDetails'

export const newsService = {
  getLatestNews: async (): Promise<INewsResponse> => {
    try {
      const response: INewsResponse = await makeRequest(
        'GET',
        ALL_ENDPOINTS.News.GetLatestNews
      )

      return response
    } catch (error) {
      throw new Error('Get news data failed')
    }
  },
  getNewsCount: async (): Promise<INewsCountResponse> => {
    try {
      const response: INewsCountResponse = await makeRequest(
        'GET',
        ALL_ENDPOINTS.News.GetNewsCount
      )

      return response
    } catch (error) {
      throw new Error('Get news count failed')
    }
  },
  getPaginatedNews: async (
    from: number,
    to: number,
    searchTerm: string,
    categoryId: string
  ): Promise<INewsResponse> => {
    try {
      const searchTermFilter = searchTerm
        ? `%26%26 title match "${searchTerm}"`
        : ''
      const categoryFilter = categoryId
        ? `%26%26 category._id == "${categoryId}"`
        : ''
      const query = `*[_type == "news" ${searchTermFilter} ${categoryFilter}] | order(_updatedAt desc)[${from}..${to}]{
        _id,
        _updatedAt,
        title,
        "slug": slug.current,
        "image": image.asset->url,
        content
      }`

      const response: INewsResponse = await makeRequest(
        'GET',
        `${ALL_ENDPOINTS.News.GetPaginatedNews}?query=${query}`
      )

      return response
    } catch (error) {
      throw new Error('Get news count failed')
    }
  },
  getNewsCategories: async (): Promise<INewsCategoryResponse> => {
    try {
      const response: INewsCategoryResponse = await makeRequest(
        'GET',
        ALL_ENDPOINTS.News.GetNewsCategory
      )

      return response
    } catch (error) {
      throw new Error('Get news categories failed')
    }
  },
  getNewsDetails: async (slug: string): Promise<INewsDetailsResponse> => {
    try {
      const response: INewsDetailsResponse = await makeRequest(
        'GET',
        `${ALL_ENDPOINTS.News.GetNewsDetailsPrefix}${slug}${ALL_ENDPOINTS.News.GetNewsDetailsSuffix}`
      )

      return response
    } catch (error) {
      throw new Error('Get news details failed')
    }
  },
}
