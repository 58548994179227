export interface ArticleCardProps {
  image: string
  title: string
  description: string
  link: string
}

export const useArticleCard = (props: ArticleCardProps) => {
  return {
    ...props,
  }
}
