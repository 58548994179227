import React from 'react'
import { useTeam, TeamProps } from './useTeam'
import styles from './Team.module.scss'
import TitleSection from 'components/TitleSection/TitleSection'
import TeamCard from 'components/TeamCard/TeamCard'

const Team: React.FC<TeamProps> = (props: TeamProps) => {
  const { ourTeamData } = useTeam(props)

  return (
    <section id='team' className={styles.team}>
      <TitleSection title='فريقنا' />
      <div className='container'>
        <div className='row mb-4 mb-lg-5'>
          {ourTeamData &&
            ourTeamData.length &&
            ourTeamData.map((teamMember, index) => (
              <div
                key={`teamMember-${teamMember._id}-${index}`}
                className='col-12 col-md-6 col-lg-4 col-xl-3 mb-3 mb-xl-0'
              >
                <TeamCard
                  image={teamMember.personalPhotoUrl}
                  name={teamMember.name}
                  position={teamMember.position}
                  description={teamMember.description}
                  socialLinks={{
                    facebook: teamMember.fbLink,
                    instagram: teamMember.instaLink,
                    x: teamMember.twitterLink,
                    linkedIn: teamMember.linkedinLink,
                  }}
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Team
