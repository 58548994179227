import React from 'react'
import { useTag, TagProps } from './useTag'
import styles from './Tag.module.scss'

const Tag: React.FC<TagProps> = (props: TagProps) => {
  const { name, isSmall } = useTag(props)

  return (
    <span className={`${styles.tag} ${isSmall ? styles.sm : ''}`}>#{name}</span>
  )
}

export default Tag
