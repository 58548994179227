import { IBlogs } from 'models/Blogs/IBlogs'

export interface LatestArticlesProps {
  blogs: IBlogs[] | null
}

export const useLatestArticles = (props: LatestArticlesProps) => {
  return {
    ...props,
  }
}
