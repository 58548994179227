import { combineReducers } from 'redux'
import homeReducer, { HomeState } from './home.reducer'
import newsReducer, { NewsState } from './news.reducer'
import blogsReducer, { BlogsState } from './blogs.reducer'
import observatoryReducer, { ObservatoryState } from './observatory.reducer'

const rootReducer = combineReducers({
  home: homeReducer,
  news: newsReducer,
  blogs: blogsReducer,
  observatory: observatoryReducer,
})

export type RootState = {
  home: HomeState
  news: NewsState
  blogs: BlogsState
  observatory: ObservatoryState
}

export default rootReducer
