import React from 'react'
import { useArticleCard, ArticleCardProps } from './useArticleCard'
import styles from './ArticleCard.module.scss'
import { Link } from 'react-router-dom'

const ArticleCard: React.FC<ArticleCardProps> = (props: ArticleCardProps) => {
  const { image, title, description, link } = useArticleCard(props)

  return (
    <Link to={link}>
      <div className={styles['article-card']}>
        <img
          src={image}
          alt='article media'
          className={styles['article-image']}
        />
        <div className={styles['article-card-body']}>
          <h3 className={styles['article-card-title']}>
            <div className={styles.link}>{title}</div>
          </h3>
          <div className={styles['article-card-content']}>
            <p className={styles['article-card-description']}>{description}</p>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ArticleCard
