import makeRequest from 'utils/makeRequest'
import { ALL_ENDPOINTS } from 'config/apiEndpoints'
import IBlogsResponse from 'models/Blogs/IBlogs'
import IBlogsCountResponse from 'models/Blogs/IBlogsCount'
import IBlogCategoryResponse from 'models/Blogs/IBlogCategory'
import IBlogDetailsResponse from 'models/Blogs/IBlogDetails'

export const blogsService = {
  getLatestBlogs: async (): Promise<IBlogsResponse> => {
    try {
      const response: IBlogsResponse = await makeRequest(
        'GET',
        ALL_ENDPOINTS.Blogs.GetLatestBlogs
      )

      return response
    } catch (error) {
      throw new Error('Get blogs data failed')
    }
  },
  getBlogsCount: async (): Promise<IBlogsCountResponse> => {
    try {
      const response: IBlogsCountResponse = await makeRequest(
        'GET',
        ALL_ENDPOINTS.Blogs.GetBlogsCount
      )

      return response
    } catch (error) {
      throw new Error('Get blogs count failed')
    }
  },
  getPaginatedBlogs: async (
    from: number,
    to: number,
    searchTerm: string,
    categoryId: string
  ): Promise<IBlogsResponse> => {
    try {
      const searchTermFilter = searchTerm
        ? `%26%26 title match "${searchTerm}"`
        : ''
      const categoryFilter = categoryId
        ? `%26%26 category._id == "${categoryId}"`
        : ''
      const query = `*[_type == "blog" ${searchTermFilter} ${categoryFilter}] | order(_updatedAt desc)[${from}..${to}]{ 
        _id, 
        _updatedAt, 
        title, 
        "slug": slug.current, 
        "image": image.asset->url,
        description
      }`

      const response: IBlogsResponse = await makeRequest(
        'GET',
        `${ALL_ENDPOINTS.Blogs.GetPaginatedBlogs}?query=${query}`
      )

      return response
    } catch (error) {
      throw new Error('Get blogs count failed')
    }
  },
  getBlogCategories: async (): Promise<IBlogCategoryResponse> => {
    try {
      const response: IBlogCategoryResponse = await makeRequest(
        'GET',
        ALL_ENDPOINTS.Blogs.GetBlogsCategory
      )

      return response
    } catch (error) {
      throw new Error('Get blogs categories failed')
    }
  },
  getBlogDetails: async (slug: string): Promise<IBlogDetailsResponse> => {
    try {
      const response: IBlogDetailsResponse = await makeRequest(
        'GET',
        `${ALL_ENDPOINTS.Blogs.GetBlogDetailsPrefix}${slug}${ALL_ENDPOINTS.Blogs.GetBlogDetailsSuffix}`
      )

      return response
    } catch (error) {
      throw new Error('Get blog details failed')
    }
  },
}
