import ITag from 'models/common/ITag'

export interface ReportCardProps {
  title: string
  description: string
  report: string
  author: string
  authorPicture: string
  tags: ITag[]
}

export const useReportCard = (props: ReportCardProps) => {
  return {
    ...props,
  }
}
