import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL
const TIMEOUT = Number(process.env.REACT_APP_TIMEOUT)
const KEY = process.env.REACT_APP_API_KEY

const apiConfig = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT,
  headers: {
    Authorization: `Bearer ${KEY}`,
  },
})

export default apiConfig
