import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  getIndicators as getIndicatorsReducer,
  getReports as getReportsReducer,
} from 'reducers/observatory.reducer'
import { observatoryService } from 'services/observatory.service'

export const getIndicators = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await observatoryService.getIndicators()
    if (response) {
      const indicatorsData = response

      dispatch(getIndicatorsReducer({ indicatorsData }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}

export const getReports = () => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await observatoryService.getReports()
    if (response) {
      const reportsData = response

      dispatch(getReportsReducer({ reportsData }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}
