import React, { Fragment } from 'react'
import { useTeamCard, TeamCardProps } from './useTeamCard'
import styles from './TeamCard.module.scss'

const TeamCard: React.FC<TeamCardProps> = (props: TeamCardProps) => {
  const { image, name, position, description, socialLinks } = useTeamCard(props)

  return (
    <div className={styles['team-card']}>
      <div className={styles['member-img-container']}>
        <img src={image} className={styles['member-img']} alt='team media' />
        <div className={styles['social-media-links']}>
          {socialLinks.facebook ? (
            <a
              href={socialLinks.facebook}
              className={styles['social-media-link']}
            >
              <i className={`${styles.icon} ${styles.x} bi bi-twitter-x`}></i>
            </a>
          ) : (
            <Fragment />
          )}
          {socialLinks.instagram ? (
            <a
              href={socialLinks.instagram}
              className={styles['social-media-link']}
            >
              <i
                className={`${styles.icon} ${styles.facebook} bi bi-facebook`}
              ></i>
            </a>
          ) : (
            <Fragment />
          )}
          {socialLinks.x ? (
            <a href={socialLinks.x} className={styles['social-media-link']}>
              <i
                className={`${styles.icon} ${styles.instagram} bi bi-instagram`}
              ></i>
            </a>
          ) : (
            <Fragment />
          )}
          {socialLinks.linkedIn ? (
            <a
              href={socialLinks.linkedIn}
              className={styles['social-media-link']}
            >
              <i
                className={`${styles.icon} ${styles.linkedin} bi bi-linkedin`}
              ></i>
            </a>
          ) : (
            <Fragment />
          )}
        </div>
      </div>
      <div className={styles['member-info']}>
        <h4 className={styles.name}>{name}</h4>
        <span className={styles.position}>{position}</span>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  )
}

export default TeamCard
