export interface TitleSectionProps {
  title: string
  description?: string
}

export const useTitleSection = (props: TitleSectionProps) => {
  return {
    ...props,
  }
}
