import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import {
  getNewsDetails,
  getLatestNews,
  clearNewsDetails,
} from 'actions/news.action'
import { RootState } from 'reducers/rootReducer'
import { INews } from 'models/News/INews'
import ITag from 'models/common/ITag'

export interface NewsDetailsProps {}

export const useNewsDetails = (props: NewsDetailsProps) => {
  const dispatch = useAppDispatch()

  const { slug } = useParams<{ slug: string }>()

  const [latestNews, setLatestNews] = useState<INews[]>([])
  const [tags, setTags] = useState<ITag[]>([])

  const newsDetails = useSelector((state: RootState) => state.news.newsDetails)
  const latestNewsData = useSelector(
    (state: RootState) => state.news.latestNewsData
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!newsDetails && slug) dispatch(getNewsDetails(slug))
  }, [newsDetails, slug])

  useEffect(() => {
    if (!latestNewsData) dispatch(getLatestNews())

    if (latestNewsData && latestNewsData.length) {
      const latestNewsTemp = latestNewsData.filter((news) => news.slug !== slug)

      setLatestNews(latestNewsTemp)
    }
  }, [latestNewsData, slug])

  useEffect(() => {
    if (newsDetails && newsDetails.length) setTags(newsDetails[0].tags)
  }, [newsDetails])

  useEffect(() => {
    return () => {
      dispatch(clearNewsDetails())
    }
  }, [dispatch, slug])

  return {
    ...props,
    newsDetails,
    latestNews,
    tags,
  }
}
